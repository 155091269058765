<template>
  <div class="box">
    <div class="headerFixed">
      <!-- 顶部搜索栏 -->
      <div class="searchBox">
        <div class="inputBox">
          <img src="@/assets/img/search.svg" class="icon" />
          <input
            type="text"
            placeholder="请输入技师名称"
            class="input-style"
            v-model="searchText"
            @keyup.enter="search"
          />
        </div>
         <van-button type="primary" size="mini" class="operationBtn" color="#E82B2F" round @click.stop="getData">搜索</van-button>
      </div>
     
    </div>

    <!-- 列表 -->
    <div class="list">
      <TechnicianList
        :dataList="dataList"
        @chooseAttentdant="chooseAttentdant"
      />
    </div>
  </div>
</template>

<script>
import TechnicianList from "@/components/technicianList";
import { screenArtificer } from "@/api/service";

export default {
  data() {
    return {
      searchText: "",
      page: 1,
      dataList: [],
      lng: 116.397455,
      lat: 39.91908711,
    };
  },
  components: {
    TechnicianList
  },
  created(){
    this.lat = this.$route.query.lat
    this.lng = this.$route.query.lng
  }, 
  methods: {
    getData() {
      this.dataList = []
      if(this.searchText == ""){
        this.$toast("请输入技师名称");
        return
      }
      let params = {
        pageIndex: this.page,
        pageSize: 100,
        lng: this.lng,
        lat: this.lat,
        artificerName: this.searchText
      };

      screenArtificer(params)
        .then(res => {
          if (res.code == 10000) {
            this.dataList = this.dataList.concat(res.data.records);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    chooseAttentdant(item) {
      console.log(item);
    },
    search() {
      this.page = 1
      this.dataList= []
      this.getData();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/base.less";

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2rem;

  .headerFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
  }

  .list {
    flex: 1;
    overflow-y: auto;
  }
}

.searchBox {
  .marginBox();
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  padding: 0.25rem 0.6rem;
  display: flex;
  align-items: center;
  font-size: 0.35rem;

  .operationBtn {
      width: 1.5rem;
  }

  .inputBox {
    flex: 1;
    display: flex;
    align-items: center;

    .icon {
      width: 0.45rem;
      height: 0.45rem;
      margin-left: 0.1rem;
    }

    .input-style {
      background-color: #f5f5f5;
      .input-style();
    }
  }
}
</style>
