<template>
  <div class="tech-box">
    <div
      class="content-li"
      v-for="(item, index) in dataList"
      :key="item.artificerId"
      @click.stop="clickAttendant(item)"
    >
      <img class="userImg" :src="item.avatar" alt="" />
      <div class="userInfo">
        <p>
          <!-- <span class="userState">可服务</span> -->
          <span class="name">{{ item.nickName }}</span>
        </p>
        <p class="rate-p">
          <van-rate v-model="item.star" size="15" readonly color="orange" />
          {{ item.star }}
        </p>
        <p class="plane">
          <span  @click.stop="distanceAttendant(item)">
            <svg-icon icon-class="plane-2" class="planeIcon"></svg-icon>
            {{ item.distance }}km
          </span>
          <span>关注度 {{ item.repeatCustomer>99?'99+':item.repeatCustomer }}</span>
        </p>
      </div>
      <div class="time-show-div">
        <p class="time-show" v-if="item.isOutOfRange != 1">
          <span>最早可约</span>
          <span>{{ getToDay(item.earliestAppointmentDate) }}
            {{ item.earliestAppointmentTime }}
          </span>
        </p>
        <p class="time-show" v-else>
          <span>超出范围</span>
        </p>
        <!-- <p class="drop" @click.stop="dropClick(index)"><span></span><span></span><span></span></p> -->
        <p
          class="popover-div"
          v-if="item.showPopover"
          @click.stop="unlikeAttendant(item, index)"
        >
          <svg-icon icon-class="unlike" class="unlikeIcon"></svg-icon
          ><span>不喜欢该技师</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getToDay } from "@/utils/index";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    getToDay(str) {
      return getToDay(str);
    },
    clickAttendant(item) {
      // this.$emit('chooseAttentdant',item)
      if (item.isOutOfRange == 1) {
        this.$toast("该技师已超出接单范围，请选择别的技师！");
        return false; 
      }
      this.$router.push({ path: "/attendantDetails", query: { id: item.id } });
      // let isCode = this.getwxcode('/attendantDetails')
      // if(isCode){
      //    this.$router.push({path:'/attendantDetails', query:{id:item.id}})
      // }
    },
    distanceAttendant(i){
      this.$emit('distanceAttendant', i)
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.tech-box{
  padding-bottom: .3rem;
}
.content-li {
  display: flex;
  background: white;
  border-radius: 0.2rem;
  padding: 0.3rem;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 0.3rem;
  }
  .userImg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.4rem;
  }
  .userInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      font-weight: bold;
    }
    .plane {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span{
        flex: 1;
        &:first-child{
          color: #1296db;
        }
      }
    }
  }
  .time-show-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .time-show {
    display: flex;
    flex-direction: column;
    font-size: 0.28rem;
    color: #eb4e52;
    border: solid 1px #f9caca;
    border-radius: 3px;
    span {
      padding: 2px 5px;
      &:first-child {
        background: #fbdddf;
      }
    }
  }
  .drop {
    display: flex;
    flex-direction: column;
    width: 0.5rem;
    align-items: center;

    span {
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }
  .popover-div {
    position: absolute;
    right: 0.4rem;
    bottom: 0.2rem;
    background: rgb(228, 223, 223);
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.18rem 0.2rem;
    border-radius: 0.1rem;
    box-shadow: 1px 1px 1px 1px rgb(228, 223, 223);
    .unlikeIcon {
      font-size: 0.4rem;
      margin-right: 0.1rem;
    }
  }
}
</style>
